import { createAction, props } from '@ngrx/store';
import {
  LOAD_DOWNLOAD_REPORT_TASKS,
  LOAD_DOWNLOAD_REPORT_TASKS_ERROR,
  STORE_DOWNLOAD_REPORT_TASKS,
} from './action-types';

export const loadDownloadReportTasks = createAction(
  LOAD_DOWNLOAD_REPORT_TASKS,
  props<{ forceFetch: boolean }>()
);
export const loadDownloadReportTasksSuccess = createAction(
  STORE_DOWNLOAD_REPORT_TASKS,
  props<{ payload: any[] }>()
);
export const loadDownloadReportTasksFailure = createAction(
  LOAD_DOWNLOAD_REPORT_TASKS_ERROR,
  props<{ error: any }>()
);
